<template>
  <div>
    <div class="top_left">
      <!-- <img src="../assets/img/about.jpg" alt="" /> -->
    </div>
    <div class="titlebox">
      <div class="spanbox">
        <span class="span1">首页</span><span class="span2">></span
        ><span class="span3">关于禧闻</span>
      </div>
    </div>
    <div class="about">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公司介绍" name="1">
          <div class="company company_jieshao">
            <swiper5></swiper5>
          </div>
          <div class="company">
            <div style="margin-bottom: 50px">
              <p class="company_p">
                广州禧闻信息技术有限公司位于广东省广州市海珠区，是一家通过运用移动互联网、物联网和大数据技术进行创新融合，基于BIM技术在国内建筑行业、智慧医疗、智慧园区、指挥调度平台中的信息化应用致力于对信息管理系统开发、控制和人工智能系统研究，安全工程智能化监测的智能化系统集成高科技企业。公司专注于BIM、数字化建设、智慧医疗领域的技术创新与模式变革，是国内领先的BIM、企业数字化建设服务、智慧医疗解决方案提供商和软硬件产品供应商，拥有业内领先的自主核心技术、丰富的产品线以及成熟的解决方案。公司凭借多年技术实践与沉淀，研发出“智能建筑数字孪生+BIM运维管理平台”、“企业数字化转型建设系统”、“后勤医疗虚拟现实模拟指挥调度实训平台”，以此来针对不同行业，满足用户定制化管理需求。
              </p>
              <p class="company_p">
                公司自成立以来，现有员工100余人，核心成员均来自专业从事信息管理系统开发、控制和信息化管理系统研究、安全工程智能化监测、开发的单位，设计团队和营销团队核心成员自2011年开始，对BIM技术在智慧城市、人防工程以及民用建筑项目中的信息化应用进行探索研究，结合工业AI技术与工业机理，深度融合模型与数据，精确映射生产及运维环境,从而搭建起我们的“智能建筑数字孪生+BIM运维管理平台”，我们能够为企业提供全面的基于互联网、移动互联网、图像识别以及云计算等各种智能化系统集成解决方案，实现智慧建筑的设施设备、维修保养、日常巡检、租户管理、报表管理、统计信息等内容,为客户提供更加成熟的施工进度计划管理服务，可以为整个项目的各参与方提供实时共享的工程数据。包括BIM模型设计、BIM技术应用、BIM平台搭建、BIM技能培训，运用物联网、大数据、云计算等技术，实施全生命周期数字建设的BIM应用，是国内先进的创新型BIM应用技术高科技公司。
              </p>
              <p class="company_p">
                广州禧闻信息技术有限公司在数字转化、企业信息化建设中，通过运用设备管理云+工业物联网平台运维模式深度应用新一代数字智能技术，基于边缘计算、设备管理、数据采集的服务体系，一站式连接工业自动化设备、工业网关、企业信息化设备等，从而搭建起我们的“企业数字化转型建设系统”来应用于安全生产、节能减排、研发设计、工业电子商务、制造业互联网“双创”、企业上云等方面的解决方案，实现数据标准化、数据打通、数据应用、业财实时互联互通；基于云原生架构，打造开放、互联、融合、智能的一体化云平台，聚焦数智化管理、数智化经营、数智化商业等三大企业数智化转型战略方向，提供涵盖数字营销、财务共享、全球司库、智能制造、敏捷供应链、人才管理、智慧协同等解决方案，帮助大型企业全面落地数智化。
              </p>
              <p class="company_p">
                广州禧闻信息技术在智慧医疗中，运用GIS、BIM模型数据建设出“智慧医疗虚拟仿真训练系统”实现模拟急救中心指挥调度、现场救治场景的教学、实训及考核应用等。
              </p>
              <p class="company_p">
                广州禧闻信息技术有限公司立足中国，放眼世界，以市场为导向，以科技报国为己任，脚踏实地，开拓创新，锐志进取，以我们的微薄之力为客户、为社会、为国家创造出有质量、有品味、超凡脱俗的创新技术。
              </p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="公司资质" name="2">
          <swiperTow v-if="show"></swiperTow>
        </el-tab-pane>
      </el-tabs>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from "../components/footers.vue";
import swiper5 from "../components/swiper5.vue";
import swiperTow from "../components/swiperTow.vue";
import Swiper from "swiper";
export default {
  data() {
    return {
      list: [
        require("../assets/img/certificate.jpg"),
        require("../assets/img/certificate (1).jpg"),
        require("../assets/img/certificate (2).jpg"),
        require("../assets/img/certificate (3).jpg"),
      ],
      show: false,
      activeName: "1",
    };
  },
  components: {
    swiperTow,
    swiper5,
    footers,
  },
  mounted() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "2") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.top_left {
  width: 100%;
  height: 300px;
  background: url("../assets/img/about.jpg") no-repeat left center;
  background-size: 100% 100%;
}
.about {
  width: 1000px;
  margin: 20px auto;
}
/deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #0b5393;
  color: #fff;
}

/deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item,
/deep/.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #333;
  font-size: 16px;
  width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
/deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active:hover {
  color: #fff;
}
/deep/.el-tabs--border-card > .el-tabs__header {
  border-bottom: 1px solid #0b5393;
}
/deep/.el-tabs--border-card {
  border-top: none;
}
.company {
  padding: 0 85px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.company_p {
  text-indent: 2em;
  font-size: 16px;
  color: #333;
  line-height: 30px;
  text-align: justify;
  // margin: 0;
}
.titlebox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 60px;
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  span {
    font-size: 12px;
  }
  .span1 {
    color: #073763;
  }
  .span2 {
    margin: 0 10px;
  }
  .span3 {
    color: #000;
  }
}

@media (max-width: 750px) {
  .top_left {
    width: 100%;
    height: 150px;
    background: url("../assets/img/about.png") no-repeat left center;
    background-size: 100% 100%;
  }
  .titlebox {
    display: none;
  }
  /deep/.el-tabs__item {
    width: 100px !important;
      height: 32px !important;
      line-height: 32px !important;
  }
  .about {
    width: 100%;
  }
  .company_jieshao {
    display: none;
  }
  .company {
    padding: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
  }
   .company_p{
    font-size: 14px;
  }
}
</style>
<template>
  <!--基础存放容器-->
  <div >
  
     <el-carousel :autoplay='true' arrow="always" :interval="2000"  height="240px">
        <el-carousel-item v-for="item in list" :key="item">
          <img class="wh100p" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
  </div>
</template>

  
<script>
import Swiper from "swiper";
export default {
    data(){
        return{ 
          list:[
            require("../assets/img/banner (1).jpg"),
            require("../assets/img/banner (2).jpg"),
            require("../assets/img/banner (3).jpg"),
          ]
         }
    },
    mounted() {
      // this.initSwiper();
    },
    methods: {
    initSwiper() {
        var mySwiper = new Swiper(".swiper-container3", {
          loop: true,
          // speed: 6000, //匀速时间
          autoplay: 2000,
          // spaceBetween:12,
          slidesPerView : 'auto',
          autoplayDisableOnInteraction: false,
          pagination: ".swiper-pagination",
           // 如果需要前进后退按钮
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',

        });
        // var comtainer = document.getElementById('swiper-container3');
        // comtainer.onmouseenter = function () {
        //   mySwiper.stopAutoplay();
        // };
        // comtainer.onmouseleave = function () {
        //   mySwiper.startAutoplay();
        // }
      },
    },
}
</script>
<style scoped lang='less'>
.wh100p{
    width: 100%;
    height: 100%;
}
/deep/.el-carousel__button{
  width: 10px;
  height:10px;
  border-radius: 50%;
}
/deep/.el-carousel__arrow{
  width: 30px;
  height: 50px;
  border-radius: 0;
  background-color: rgba(31,45,61,.25);
  i{
    font-weight: 700;
    font-size: 15px;
  }
}
@import "../../node_modules/swiper/dist/css/swiper.css";

.swiper-slide img {
  width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: 100% 100%
}
.swiper-pp{
  margin:0;
  margin-top: 5px;

}
/deep/.swiper-button-prev,/deep/.swiper-button-next{
  top: 135px;
  width: 30px;
  height: 50px;
  border-radius: 0;
  color: #000;
  background-size: 60% 35%;
  background-color: rgba(31,45,61,.25);
}
/deep/.swiper-button-prev{
  left: 110px;
  font-size: 12px;
}
/deep/.swiper-button-next{
  right: 110px;
}
.swiper-pagination{
  height: 20px;
  width: 800px !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets{
  left: 100px !important;
  top: 230px;
}


@media (max-width: 750px) {
  .el-carousel {
   width: 100%;
   height: 120px !important;
  }
  /deep/.el-carousel__item,/deep/.el-carousel__container{
    width: 100%;
    height: 120px !important;
  }
  .wh100p{
    width: 100%;
    height:120px;
    overflow: hidden;
}
}
</style>

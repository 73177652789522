<template>
  <!--基础存放容器-->
  <div class="zizhi_nerser">
    <div class="swiper-containerTow" id="swiper-containerTow">
      <div class="swiper-wrapper">
        <div class="swiper-slide" style="width:320px" v-for="(item,index) in list" :key="index">
          <img class="wh100p" :src="item" alt="">
        </div>
      </div>
      <div class="swiper-button-prev swiper-button-white"></div> 
      <div class="swiper-button-next swiper-button-white"></div>
      
    </div>
  </div>
</template>

  
<script>
import Swiper from "swiper";
export default {
    data(){
        return{ 
           list:[
            require("../assets/img/certificate.jpg"),
            require("../assets/img/certificate (1).jpg"),
            require("../assets/img/certificate (2).jpg"),
            require("../assets/img/certificate (3).jpg"),
          ]
        }
    },
    mounted() {
      this.initSwiper();
    },
    methods: {
    initSwiper() {
        var mySwiper = new Swiper(".swiper-containerTow", {
          loop: true,
          speed:5000, //匀速时间
          autoplay: {
            delay: 0,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          spaceBetween:12,
          slidesPerView : 'auto',
          autoplayDisableOnInteraction: false,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
        });
        var comtainer = document.getElementById('swiper-containerTow');
        comtainer.onmouseenter = function () {
          mySwiper.stopAutoplay();
        };
        comtainer.onmouseleave = function () {
          mySwiper.startAutoplay();
        }
      },
    },
}
</script>
<style scoped lang='less'>

// @import "../../node_modules/swiper/dist/css/swiper.css";

.zizhi_nerser{
  padding: 0 50px;
  box-sizing: border-box;
  .swiper-containerTow{
    width:860px !important;
    height: 315px;
    overflow: hidden;
  }
  .swiper-slide{
    width: 275px !important;
    border-radius: 10px;
    padding:15px 40px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin-right: 15px;

  }
  .swiper-slide img {
    width: 202px;
    height: 285px;
    border-radius: 10px;
    background-size: 100% 100%
  }
  .swiper-pp{
    margin:0;
    margin-top: 5px;
  }
  /deep/.swiper-button-prev,/deep/.swiper-button-next{
    top: 165px;
    width: 30px;
    height: 50px;
    border-radius: 0;
    color: #000;
    background-size: 60% 35%;
    background-color: rgba(31,45,61,.25);
  }
  /deep/ .swiper-button-prev{
    left:30px;
  }
  /deep/ .swiper-button-next{
    right: 43px;
  }
}
@media (max-width: 750px) {
  .zizhi_nerser{
    padding: 0;
    // border: 1px solid #000;
    overflow: hidden;
    .swiper-containerTow{
      height: 265px;
    }
    .swiper-slide{
    width: 200px !important;
    padding: 10px;
   

  }
  .swiper-slide img {
    width: 180px;
    height: 250px;
  }
  }
    /deep/.swiper-button-prev,/deep/.swiper-button-next{
      display: none;
    }
}

</style>
